export function niTransformOnsets(
    onsets: number[],
    niWeights: number[],
    strength: number
): number[] {
    const nPulsesPerBeat = niWeights.length
    if (nPulsesPerBeat <= 0)
        throw new Error('nPulsesPerBeat must be greater than 0')

    const equidistant_pulse_lengths = Array(nPulsesPerBeat).fill(
        1 / nPulsesPerBeat
    )

    let pulse_lengths = equidistant_pulse_lengths.map(
        (length, index) => length + strength * (niWeights[index] - length)
    )

    // ensure pulse_length sum to 1 and is positive (minimum 0)
    pulse_lengths = pulse_lengths.map((x) => Math.max(0, x))
    pulse_lengths = pulse_lengths.map(
        (x) => x / pulse_lengths.reduce((a, b) => a + b, 0)
    )

    const niDurations = pulse_lengths.map((x) => x * nPulsesPerBeat)

    // Compute the cumulative sums to get positions in the grid
    const isoCumsum = Array.from({ length: nPulsesPerBeat }, (_, i) => i + 1) // e.g. [1, 2, 3]
    const niCumsum = niDurations.reduce((acc: number[], val) => {
        acc.push((acc.length ? acc[acc.length - 1] : 0) + val)
        return acc
    }, [])

    const transformedOnsets: number[] = []

    onsets.forEach((onset) => {
        const beatIndex = Math.floor(onset / nPulsesPerBeat) // Determine the beat number (integer division)
        const pulseWithinBeat = onset % nPulsesPerBeat // The position within the current beat (remainder)

        let newOnsetWithinBeat: number | null = null

        // Find which original pulse range the onset belongs to
        for (let i = 0; i < nPulsesPerBeat; i++) {
            if (pulseWithinBeat < isoCumsum[i]) {
                if (i === 0) {
                    newOnsetWithinBeat =
                        (pulseWithinBeat / isoCumsum[i]) * niCumsum[i]
                } else {
                    newOnsetWithinBeat =
                        niCumsum[i - 1] +
                        ((pulseWithinBeat - isoCumsum[i - 1]) /
                            (isoCumsum[i] - isoCumsum[i - 1])) *
                            (niCumsum[i] - niCumsum[i - 1])
                }
                break
            }
        }

        if (newOnsetWithinBeat === null) {
            throw new Error('newOnsetWithinBeat is null')
        }

        // Get the final onset time
        const transformedOnset = beatIndex * nPulsesPerBeat + newOnsetWithinBeat // Scale back to original total length
        transformedOnsets.push(transformedOnset)
    })

    return transformedOnsets
}

export default niTransformOnsets
