import React, { useState, useEffect, useRef } from 'react'
import * as Tone from 'tone'
import { Instrument } from './Instrument.tsx'
import deepEqual from 'deep-equal'
import { useFileContext } from './context/FileContext.tsx'
import niTransformOnsets from './core/Microtiming.tsx'
import { useDebouncedValue, usePrevious } from '@mantine/hooks'
import { InstrumentConf, ParsedPiece, PlaybackItem } from './types/ParsedPiece'
import { PlaybackItemDesc } from './types/PlaybackItemDesc'

export interface MultiInstrumentPlayerProps {
    parsedPiece: ParsedPiece | null
    selectedPlaybackItemDesc: PlaybackItemDesc | null
    doPlayAudio: boolean
}

const MultiInstrumentPlayer: React.FC<MultiInstrumentPlayerProps> = ({
    parsedPiece,
    doPlayAudio,
    selectedPlaybackItemDesc,
}) => {
    const [parts, setParts] = useState<Tone.Part[] | null>(null)
    // const [playbackItem, setPlaybackItem] = useState<PlaybackItem>(null)
    const prevSelectedPlaybackItem = useRef<PlaybackItem | null>(null)
    const prevInstrumentConf = useRef<InstrumentConf | null>(null)

    const {
        instruments,
        setInstruments,
        bpm,
        microtimingPattern,
        microtimingStrength,
    } = useFileContext()

    useEffect(() => {
        Tone.Transport.bpm.value = bpm
    }, [bpm])

    useEffect(() => {
        const startTone = async () => {
            await Tone.start()
            // @ts-expect-error for debugging
            window.Tone = Tone

            console.log('Audio context started')
            document.removeEventListener('click', startTone)
            document.removeEventListener('keydown', startTone)
        }

        document.addEventListener('click', startTone, { once: true })
        document.addEventListener('keydown', startTone, { once: true })

        return () => {
            document.removeEventListener('click', startTone)
            document.removeEventListener('keydown', startTone)
        }
    }, [])

    useEffect(() => {
        if (!parsedPiece) return

        const instrumentConf = parsedPiece.instrument_conf

        // return if instrumentConf has not changed to avoid reloading instruments
        if (deepEqual(instrumentConf, prevInstrumentConf.current)) return
        prevInstrumentConf.current = instrumentConf

        const loadInstrument = async () => {
            const instrumentNames = Object.keys(instrumentConf.instrument_specs)
            const newInstruments: { [key: string]: Instrument } = {}

            for (const instrumentName of instrumentNames) {
                const channelNames =
                    instrumentConf.channel_names_by_instrument[instrumentName]

                for (const channelName of channelNames) {
                    const samplepackName = channelName
                        .split('_')
                        .slice(0, -1)
                        .join('_')
                    const specUrl = `soundsprites/${samplepackName}.json`
                    const audioUrl = `soundsprites/${samplepackName}.webm`

                    try {
                        const response = await fetch(specUrl)
                        const spec = await response.json()
                        newInstruments[channelName] = new Instrument(
                            channelName,
                            spec,
                            audioUrl,
                            instrumentConf.default_panning[channelName],
                            instrumentConf.default_volumes[channelName]
                        )
                        await newInstruments[channelName].loadPromise
                    } catch (error) {
                        console.error(
                            'Error loading instrument or spec:',
                            error
                        )
                    }
                }
            }
            setInstruments(newInstruments)
        }

        loadInstrument()
    }, [parsedPiece])

    // useEffect(() => {
    //     Tone.Transport.bpm.value = parsedPiece?.bpm
    // }, [parsedPiece])

    const handlePlayPause = async (
        isPlaybackItemChanged: boolean,
        playbackItem: PlaybackItem
    ) => {
        // console.log('selectedPlaybackItemDesc', selectedPlaybackItemDesc)
        if (!parsedPiece) return
        if (!playbackItem) {
            Tone.Transport.stop()
            return
        }
        if (!instruments) return // wait for instruments to load

        // console.log('handlePlayPause', isPlaybackItemChanged)

        // update parts if playback item has changed
        if (isPlaybackItemChanged || !parts) {
            // stop all parts
            parts?.forEach((part) => part.stop())

            Tone.Transport.timeSignature = parsedPiece.n_pulses_per_beat

            const newParts: Tone.Part[] = []
            for (const instrumentId in playbackItem.tracks) {
                for (const track of playbackItem.tracks[instrumentId]) {
                    if (!instruments[instrumentId]) return
                    await instruments[instrumentId].loadPromise
                    const transformedTimes = microtimingPattern
                        ? niTransformOnsets(
                              track.times,
                              microtimingPattern,
                              microtimingStrength
                          )
                        : track.times
                    // log transformed times if there are not strictly monotonically increasing
                    if (
                        transformedTimes.some(
                            (time: number, i: number) =>
                                i > 0 && time <= transformedTimes[i - 1]
                        )
                    ) {
                        console.log(
                            'Transformed times are not strictly monotonically increasing:',
                            track.times,
                            transformedTimes
                        )
                    }
                    const part = await instruments[instrumentId].create_part(
                        transformedTimes,
                        track.sounds,
                        track.length
                    )

                    newParts.push(part)
                }
            }
            setParts(newParts)
            // console.log('updated Parts', newParts)

            // if (doPlayAudio) {
            // start all parts
            newParts.forEach((part) => part.start(0))
            // }

            console.log('Parts changed')
        }

        if (!doPlayAudio) {
            // stop all parts
            Tone.Transport.stop()
            // parts?.forEach((part) => part.stop())
        } else {
            console.log('Tone.Transport.state', Tone.Transport.state)
            if (Tone.Transport.state !== 'started') {
                // console.log(
                //     'Awaiting Tone.start(), then starting parts and Tone.Transport'
                // )
                await Tone.start()

                let start_pulse = parsedPiece.properties.start_pulse
                if (start_pulse < 0) {
                    start_pulse += playbackItem.length
                }
                // Tone.Transport.setLoopPoints(0, `0:0:${playbackItem.length}`)
                // Tone.Transport.loop = true

                // parts?.forEach((part) => part.start(0))
                const start_time = `0:0:${start_pulse}`
                Tone.Transport.start('+0', start_time)
                // Tone.Transport.start()
                console.log('Tone.Transport started at time:', start_time)
            }
        }
    }

    const [debouncedMicrotimingStrength] = useDebouncedValue(
        microtimingStrength,
        50
    )
    const prevMicrotimingPattern = usePrevious(microtimingPattern)
    const prevMicrotimingStrength = usePrevious(debouncedMicrotimingStrength)

    useEffect(() => {
        if (!parsedPiece || !selectedPlaybackItemDesc) return

        // console.log('selectedPlaybackItemDesc', selectedPlaybackItemDesc)

        const playbackItem =
            parsedPiece.playback_items.find(
                (item) =>
                    item.name === selectedPlaybackItemDesc.name &&
                    item.itemtype === selectedPlaybackItemDesc.itemtype &&
                    item.instrument_name ===
                        selectedPlaybackItemDesc.instrument_name
            ) ??
            parsedPiece.playback_items.find(
                (item) => item.element_name === 'Standard'
            )

        if (!playbackItem) {
            console.error(
                'No playback item found for selectedPlaybackItemDesc:',
                selectedPlaybackItemDesc
            )
            return
        }

        console.log(selectedPlaybackItemDesc, playbackItem)

        const isSelectedPlaybackItemDescChanged =
            playbackItem !== prevSelectedPlaybackItem.current ||
            prevMicrotimingPattern !== microtimingPattern ||
            prevMicrotimingStrength !== debouncedMicrotimingStrength

        console.log(
            selectedPlaybackItemDesc,
            playbackItem,
            isSelectedPlaybackItemDescChanged
        )

        prevSelectedPlaybackItem.current = playbackItem

        handlePlayPause(isSelectedPlaybackItemDescChanged, playbackItem)
        // if (doPlayAudio && !isPlaying) {
        //     handlePlayPause(false)
        // } else if (!doPlayAudio && isPlaying) {
        //     handlePlayPause(false)
        // }
    }, [
        selectedPlaybackItemDesc,
        parsedPiece,
        doPlayAudio,
        debouncedMicrotimingStrength,
        microtimingPattern,
    ])

    return null
}

export default MultiInstrumentPlayer
