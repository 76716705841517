import { Button } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
import { useFileContext } from '../context/FileContext.tsx' // Assuming you're using Tabler icons

function SaveFileButton() {
    const { editorContent, fileName } = useFileContext()
    // Function to save file using File System Access API or fallback
    const saveFile = async () => {
        try {
            const options = {
                types: [
                    {
                        description: 'Text File',
                        accept: { 'text/plain': ['.txt', '.md'] },
                    },
                ],
                suggestedName: fileName,
            }

            // Check for support and open save file picker
            if ('showSaveFilePicker' in window) {
                // @ts-expect-error for now
                const fileHandle = await window.showSaveFilePicker(options)
                const writable = await fileHandle.createWritable()
                await writable.write(editorContent)
                await writable.close()
                alert('File saved successfully!')
            } else {
                // Fallback method if File System Access API is not available
                fallbackSave()
            }
        } catch (error) {
            console.error('Failed to save file:', error)
            // Fallback method if there was an error using the File System Access API
            // fallbackSave()
        }
    }

    // Fallback method to download the file when File System Access API is not available or fails
    const fallbackSave = () => {
        const blob = new Blob([editorContent], {
            type: 'text/plain;charset=utf-8',
        })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName // Use the provided file name or default
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url)
    }

    return (
        <Button
            onClick={() => saveFile()}
            leftSection={<IconDownload size={14} />}
            variant="light"
            radius="md"
            color="var(--bright-color)"
            size="sm"
        >
            Save
        </Button>
    )
}

export default SaveFileButton
