export const initial_editor_content = `---
name: Neuer Rhythmus
subtitle: ( GrooveDoc Beispiel - Binär )
note_topright: ${new Date().toLocaleDateString('de-DE')}
n_pulses_per_beat: 4
bpm: 90
do_plot_sequences: False
---

# Abfolge
1x Standard
2x Standard + Begleitung 1
1x Standard + Solo mit Rollern
2x Chauffee
1x Standard + Blockage

# kenkeni
|o   o   o   o   |

# sanban
|. .. .. . . . . |
|o     x x   o   |

# dundunba
|.. . .. . .. .. |
|oo o      oo oo |

# dundunba Chauffee
| . . . .. . . ..|
| o o o oo o o oo|

# djembe Begleitung 1
|x  xx oo|

# djembe Begleitung 2
|O oo  x |

# djembe Chauffee
|oooxxxxxxxxxxxxx|

# djembe Blockage
|f oo o oo o o   |

# djembe Solomöglichkeit
|oo x  x oo x  x |
|oo  oo  oo x  x |

# djembe Solo mit Rollern
|1oo1oxoox x 1oo1oxoox |
|x 1oo1oxoox x 1oo1oxoo|
|xx X            |


// Das hier ist ein Kommentar, der nicht in den Noten auftaucht.
// Es folgt eine vollständige Übersicht der Notation:

// Dunduns:
//   Schlag - o, Abgestoppter - x, Glocke - ., Holzrand - *

// Djembe:
//   Ton - o, Slap - x, Bass - O,
//   Flam (tones) - f, Flam (slaps) - F oder X
//   Flam (bass+ton): g, Flam (bass+slap) - G
//   Flam (slap+ton) h, Flam (ton+slap) - H
//   Gedämpfter Ton - j, Gedämpfter slap - m,

// Djembe-Roller:
//   1ox1 sind ein Ton und ein Slap, die zusammen einen Puls lang sind.
//   2xxx2 sind drei Slaps, die zusammen zwei Pulse lang sind.

// GrooveDoc ist in aktiver Entwicklung.
// Bitte speichere regelmäßig deinen Fortschritt!
// Feedback ist willkommen unter: mail@groovedoc.app
// Hermann.


`
