import {
    IconPlayerPlay,
    IconPlayerPlayFilled,
    IconPlayerStopFilled,
} from '@tabler/icons-react'
import { Button, darken } from '@mantine/core'
import { ReactNode, useState } from 'react'
import { useFileContext } from '../context/FileContext.tsx'
import { PlaybackItemDesc } from '../types/PlaybackItemDesc'
import { shallowEqual } from '@mantine/hooks'

export function GridPlayButton({
    children,
    playbackItemDesc,
}: {
    children?: ReactNode
    playbackItemDesc: PlaybackItemDesc
}) {
    const [hovered, setHovered] = useState(false)

    const {
        selectedPlaybackItem,
        setSelectedPlaybackItem,
        setDoPlayAudio,
        doPlayAudio,
        parsedPiece,
    } = useFileContext()

    const isSequence = playbackItemDesc.itemtype === 'sequence'

    const isSelected = shallowEqual(playbackItemDesc, selectedPlaybackItem)

    const icon = (() => {
        if (isSelected && doPlayAudio) {
            if (hovered) {
                return <IconPlayerStopFilled size={16} />
            } else {
                return <IconPlayerPlayFilled size={16} />
            }
        } else if (hovered) {
            return <IconPlayerPlayFilled size={16} />
        } else {
            return <IconPlayerPlay size={16} />
        }
    })()

    const colorsActive = {
        dundunba: '#975C52',
        sanban: '#C59051',
        kenkeni: '#998D62',
        djembe: '#657500',
    }

    // const colorsInactive = {
    //     djembe: '#DC9D81',
    //     dundunba: '#E1AE6D',
    //     sanban: '#DDC58A',
    //     kenkeni: '#92AA8A',
    // }

    let color: string
    if (playbackItemDesc.instrument_name !== null) {
        // @ts-expect-error later
        color = colorsActive[playbackItemDesc.instrument_name]
    } else {
        color = '#455262'
    }

    const isActive =
        playbackItemDesc.itemtype == 'voice' &&
        (selectedPlaybackItem?.name === playbackItemDesc.name ||
            (playbackItemDesc.name === 'Standard' &&
                // @ts-expect-error later
                // eslint-disable-next-line no-prototype-builtins
                !parsedPiece?.playback_voices.hasOwnProperty([
                    selectedPlaybackItem?.name,
                    playbackItemDesc.instrument_name,
                ]))) &&
        selectedPlaybackItem?.itemtype === 'part'

    const button = (
        <Button
            variant={isSelected && doPlayAudio ? 'filled' : 'filled'}
            color={
                (isActive || isSelected) && doPlayAudio
                    ? color
                    : darken(color, 0.4)
            }
            disabled={false}
            radius={isSequence ? 'xl' : 'md'}
            onClick={() => {
                setSelectedPlaybackItem(playbackItemDesc)

                if (isSelected && doPlayAudio) {
                    setDoPlayAudio(false)
                } else {
                    setDoPlayAudio(true)
                }
            }}
            size={isSequence ? 'sm' : 'sm'}
            // p={'md'}
            fullWidth={!isSequence}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            leftSection={children ? icon : null}
            // styles={(theme) => ({
            //     root: {
            //         border:
            //             isSelected && doPlayAudio
            //                 ? `2px solid ${theme.colors.gray[4]}`
            //                 : 'none',
            //     },
            // })}
        >
            {children ?? icon}
        </Button>
    )

    // return isSelected && doPlayAudio ? (
    //     <Indicator
    //         color={'white'}
    //         position="middle-center"
    //         size={6}
    //         offset={10}
    //         // withBorder
    //         processing
    //     >
    //         {button}
    //     </Indicator>
    // ) : (
    //     button
    // )
    return button
}
