import { Container, Group, Space } from '@mantine/core'
import classes from './HeaderSimple.module.css'
import OpenFileButton from './OpenFileButton.tsx'
import SaveFileButton from './SaveFileButton.tsx'
import ExportPDFButton from './ExportPDFButton.tsx'
// import { ShareButton } from './ShareButton.tsx'
import { useMediaQuery } from '@mantine/hooks'
import { EditPreviewSwitch } from './EditPreviewSwitch.tsx'

export function HeaderMenu() {
    const isMobile = useMediaQuery('(max-width: 768px)')
    return (
        <Group className={classes.header} gap="xs">
            {/*<Button*/}
            {/*    leftSection={*/}
            {/*        props.doPlayAudio ? (*/}
            {/*            <IconVolume size={16} />*/}
            {/*        ) : (*/}
            {/*            <IconVolumeOff size={16} />*/}
            {/*        )*/}
            {/*    }*/}
            {/*    onClick={() => props.setDoPlayAudio(!props.doPlayAudio)}*/}
            {/*    variant="default"*/}
            {/*>*/}
            {/*    {props.doPlayAudio ? 'Stop Speech' : 'Start Speech'}*/}
            {/*</Button>*/}
            <OpenFileButton />
            {/*<FileButton*/}
            {/*    leftSection={<IconFolderOpen size={14} />}*/}
            {/*    variant="outline"*/}
            {/*    radius="md"*/}
            {/*    color="var(--bright-color)"*/}
            {/*    size="sm"*/}
            {/*    // color="rgba(0, 0, 0, 1)"*/}
            {/*    // @ts-expect-error  ssd*/}
            {/*    onChange={props.onFileSelected}*/}
            {/*    // accept="image/png,image/jpeg"*/}
            {/*>*/}
            {/*    {(props) => <Button {...props}>Open</Button>}*/}
            {/*</FileButton>*/}
            {/*<Button*/}
            {/*    leftSection={<IconDownload size={14} />}*/}
            {/*    onClick={() => props.onSaveFile()}*/}
            {/*    variant="outline"*/}
            {/*    size="sm"*/}
            {/*    // color="rgba(0, 0, 0, 1)"*/}
            {/*    radius="md"*/}
            {/*    color="var(--bright-color)"*/}
            {/*>*/}
            {/*    Save*/}
            {/*</Button>*/}
            <SaveFileButton />
            <Container fluid />
            {isMobile && <ExportPDFButton />}
            {!isMobile && <EditPreviewSwitch />}

            <Space w="xs" />
            {/*<ShareButton />*/}
            {/*<Burger*/}
            {/*    opened={opened}*/}
            {/*    onClick={toggle}*/}
            {/*    hiddenFrom="xs"*/}
            {/*    size="sm"*/}
            {/*/>*/}
        </Group>
    )
}
