import { useRef } from 'react'
import { IconFolderOpen } from '@tabler/icons-react'
import { Button } from '@mantine/core'
import { useFileContext } from '../context/FileContext.tsx'

function OpenFileButton() {
    const { setFileName, setEditorContent } = useFileContext()
    const fileInputRef = useRef(null)

    // Function to handle file reading via FileReader
    // @ts-expect-error for now
    const handleFileReader = (file) => {
        const reader = new FileReader()
        reader.onload = () => {
            // console.log('FileReader result:', reader.result) // Debug log
            setFileName(file.name)
            if (reader.result === null) return

            const text =
                typeof reader.result === 'string'
                    ? reader.result
                    : new TextDecoder('utf-8').decode(reader.result)
            setEditorContent(text)
        }
        reader.onerror = () => {
            console.error('Error reading file:', reader.error)
        }
        reader.readAsText(file)
    }

    // Function called when the button is clicked
    const handleButtonClick = async () => {
        if ('showOpenFilePicker' in window) {
            try {
                // @ts-expect-error for now
                const [fileHandle] = await window.showOpenFilePicker()
                const file = await fileHandle.getFile()
                const contents = await file.text()
                setFileName(file.name)
                setEditorContent(contents)
            } catch (error) {
                console.error('Error with File System Access API:', error)
            }
        } else {
            // Trigger file input click if File System Access API is not available
            // @ts-expect-error for now
            fileInputRef.current.click()
        }
    }

    // Hidden file input to handle fallback file selection
    // @ts-expect-error for now
    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            handleFileReader(file)
        }
    }

    return (
        <>
            <Button
                onClick={handleButtonClick}
                leftSection={<IconFolderOpen size={14} />}
                variant="light"
                radius="md"
                color="var(--bright-color)"
                size="sm"
            >
                Open
            </Button>
            <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
            />
        </>
    )
}

export default OpenFileButton
